import React, { useEffect } from 'react'
import { Customers } from '../constants/Customers'
import { DataGrid } from '@mui/x-data-grid'
import CustomerChart from '../charts/CustomerChart'

const Customer = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Customer List'
  })
  return (
    <div>
      <CustomerChart/>
      <div style={{ height: 700, width: '100%' }}>
        <h5>Customers</h5>
        <DataGrid rows={Customers} columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'name', headerName: 'Name', width: 130 },
          { field: 'phone', headerName: 'Phone', width: 130 },
          { field: 'email', headerName: 'Email', width: 130 },
          { field: 'address', headerName: 'Address', width: 130 },
          { field: 'points', headerName: 'Points', width: 130 },
        ]} />
      </div>
    </div>
  )
}

export default Customer