import React, { useEffect } from 'react'
import DarkMode from '../components/DarkMode/DarkMode/DarkMode'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
const Settings = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Settings'
  })
  return (
    <div>
      <h5>Settings</h5>
      <p>Customize your settings as you wish</p>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Setting</TableCell>
              <TableCell align="right">Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <div>
                  <h5>Theme</h5>
                  <p>Light Mode / Dark Mode</p>
                </div>
              </TableCell>
              <TableCell align="right">
                <DarkMode />
              </TableCell>
            </TableRow>
            {/* Add more settings rows here if needed */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Settings