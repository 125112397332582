import React, { useEffect } from 'react'

const Error = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Error 404'
  })
  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{minHeight: '100vh'}}>
        <h4>Page Not Found</h4>
        <img src="https://images.pexels.com/photos/4920849/pexels-photo-4920849.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Error" width={500} className='img-fluid rounded' />
    </div>
  )
}

export default Error