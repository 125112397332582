import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { Area,ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CustomerData, nocustomers } from '../constants/CustomerData'
import CountUp from 'react-countup';


const CustomerChart = () => {
    return (
        <div>
            <Card variant='outlined' className='p-3'>
                {/* eslint-disable-next-line */}
                <CardHeader title={
                <span>
                    Clients (
                    <CountUp end={nocustomers.totalCustomer} duration={2.5} separator="," decimals={0} />
                    )
                </span>
            } />
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart width={500} height={400} data={CustomerData}>
                        <YAxis />
                        <XAxis dataKey="monthname" />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="TargetCustomer" stroke="#482e1d" />
                        <Area type="monotone" dataKey={"NumberofCustomers"} stroke="#895d2b" fill="#895d2b" opacity={0.5} />
                    </ComposedChart>
                </ResponsiveContainer>
            </Card>
        </div>
    )
}

export default CustomerChart