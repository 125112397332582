export const Customers = [
    {
        id: 1,
        name: "Kavya",
        dob: "15/01/2000",
        phone: "9876543210",
        email: "kavya@k.com",
        address: "Kannur",
        points : 48,
    },
    {
        id: 2,
        name: "Ishita",
        dob: "23/07/1998",
        phone: "9876543210",
        email: "ishita@i.com",
        address: "Coimbatore",
        points : 158
    },
    {
        id: 3,
        name: "Surya",
        dob: "12/04/1985",
        phone: "9876543210",
        email: "surya@s.com",
        address: "Chennai",
        points : 14
    },
    {
        id: 4,
        name: "Anika",
        dob: "09/11/2003",
        phone: "9876543210",
        email: "anika@a.com",
        address: "Madurai",
        points : 125
    },
    {
        id: 5,
        name: "Maaran",
        dob: "05/03/1978",
        phone: "9876543210",
        email: "maaran@m.com",
        address: "Tiruchirappalli",
        points : 486
    },
    {
        id: 6,
        name: "Ganga",
        dob: "21/05/1992",
        phone: "9876543210",
        email: "ganga@g.com",
        address: "Salem",
        points : 4
    },
    {
        id: 7,
        name: "Priya Darshani",
        dob: "07/09/2002",
        phone: "9876543210",
        email: "priyadarshani@p.com",
        address: "Erode",
        points : 97
    },
    {
        id: 8,
        name: "Vel",
        dob: "18/02/1989",
        phone: "9876543210",
        email: "vel@v.com",
        address: "Tiruppur",
        points : 25
    },
    {
        id: 9,
        name: "Bhuvana",
        dob: "26/12/1995",
        phone: "9876543210",
        email: "bhuvana@b.com",
        address: "Thanjavur",
        points : 645
    },
    {
        id: 10,
        name: "Chakravarthy",
        dob: "14/08/1982",
        phone: "9876543210",
        email: "chakravarthy@c.com",
        address: "Vellore",
        points : 85
    },
    {
        id: 11,
        name: "Niveda",
        dob: "03/06/2001",
        phone: "9876543210",
        email: "niveda@n.com",
        address: "Kanchipuram",
        points : 445
    },
    {
        id: 12,
        name: "Aravindan",
        dob: "20/10/1979",
        phone: "9876543210",
        email: "aravindan@a.com",
        address: "Tirunelveli",
        points : 217
    },
    {
        id: 13,
        name: "Saanvi",
        dob: "31/01/1997",
        phone: "9876543210",
        email: "saanvi@s.com",
        address: "Cuddalore",
        points : 92
    },
    {
        id: 14,
        name: "Tamil",
        dob: "17/05/1984",
        phone: "9876543210",
        email: "tamil@t.com",
        address: "Tiruvannamalai",
        points : 65
    },
    {
        id: 15,
        name: "Kalpana",
        dob: "09/03/1990",
        phone: "9876543210",
        email: "kalpana@k.com",
        address: "Krishnagiri",
        points : 11
    },
    {
        id: 16,
        name: "Prakash",
        dob: "24/09/1987",
        phone: "9876543210",
        email: "prakash@p.com",
        address: "Dindigul",
        points : 135
    },
    {
        id: 17,
        name: "Iswarya",
        dob: "17/01/2005",
        phone: "8098979514",
        email: "njwebdesigning@gmail.com",
        address: "Vadanapally,Kerala",
        points : 25
    },
    {
        id: 18,
        name: "Vivek",
        dob: "29/08/1981",
        phone: "9876543210",
        email: "vivek@v.com",
        address: "Namakkal",
        points : 48
    },
    {
        id: 19,
        name: "Diya",
        dob: "11/07/2004",
        phone: "9876543210",
        email: "diya@d.com",
        address: "Sivaganga",
        points : 8
    },
    {
        id: 20,
        name: "Kanthan",
        dob: "16/12/1976",
        phone: "9876543210",
        email: "kanthan@k.com",
        address: "Karur",
        points : 88
    },
    {
        id: 21,
        name: "Subha",
        dob: "22/04/1993",
        phone: "9876543210",
        email: "subha@s.com",
        address: "Pudukkottai",
        points : 68
    },
    {
        id: 22,
        name: "Raaghavan",
        dob: "08/10/1980",
        phone: "9876543210",
        email: "raaghavan@r.com",
        address: "Theni",
        points : 45
    },
    {
        id: 23,
        name: "Vanitha",
        dob: "25/01/1988",
        phone: "9876543210",
        email: "vanitha@v.com",
        address: "Virudhunagar",
        points : 68
    },
    {
        id: 24,
        name: "Mithran",
        dob: "19/06/1985",
        phone: "9876543210",
        email: "mithran@m.com",
        address: "Ramanathapuram",
        points : 250
    },
    {
        id: 25,
        name: "Priya",
        dob: "14/03/1991",
        phone: "9876543210",
        email: "priya@p.com",
        address: "Thoothukudi",
        points : 450
    },
    {
        id: 26,
        name: "Lokesh",
        dob: "30/09/1978",
        phone: "9876543210",
        email: "lokesh@l.com",
        address: "Tirupattur",
        points : 112
    },
    {
        id: 27,
        name: "Jagganraj",
        dob: "27/03/2003",
        phone: "9952259514",
        email: "njwebdesigning@gmail.com",
        address: "Idappadi",
        points : 1000
    },
    {
        id: 28,
        name: "Vaani",
        dob: "11/08/1990",
        phone: "9876543210",
        email: "vaani@v.com",
        address: "Pollachi",
        points : 418
    },
    {
        id: 29,
        name: "Jagan",
        dob: "10/01/1977",
        phone: "9876543210",
        email: "jagan@j.com",
        address: "Ooty",
        points : 8
    },
    {
        id: 30,
        name: "Miraya",
        dob: "27/04/2002",
        phone: "9876543210",
        email: "miraya@m.com",
        address: "Karaikudi",
        points : 68
    },
    {
        id: 31,
        name: "Kabilan",
        dob: "23/10/1983",
        phone: "9876543210",
        email: "kabilan@k.com",
        address: "Avadi",
        points : 97
    },
    {
        id: 32,
        name: "Anjali",
        dob: "04/05/1989",
        phone: "9876543210",
        email: "anjali@a.com",
        address: "Tambaram",
        points : 256
    },
    {
        id: 33,
        name: "Selvan",
        dob: "12/11/1976",
        phone: "9876543210",
        email: "selvan@s.com",
        address: "Kodaikanal",
        points : 659
    },
    {
        id: 34,
        name: "Lakshmi",
        dob: "28/06/1982",
        phone: "9876543210",
        email: "lakshmi@l.com",
        address: "Tiruvallur",
        points : 21
    },
    {
        id: 35,
        name: "Neel",
        dob: "21/12/1979",
        phone: "9876543210",
        email: "neel@n.com",
        address: "Pondicherry",
        points : 35
    },
    {
        id: 36,
        name: "Priyaanka",
        dob: "07/03/1995",
        phone: "9876543210",
        email: "priyaanka@p.com",
        address: "Dharmapuri",
        points : 15
    },
    {
        id: 37,
        name: "Dhayan",
        dob: "17/09/1981",
        phone: "9876543210",
        email: "dhayan@d.com",
        address: "Salem",
        points : 64
    },
    {
        id: 38,
        name: "Bala",
        dob: "24/02/1978",
        phone: "9876543210",
        email: "bala@b.com",
        address: "Cuddalore",
        points : 97
    },
    {
        id: 39,
        name: "Janani",
        dob: "01/08/1987",
        phone: "9876543210",
        email: "janani@j.com",
        address: "Kumbakonam",
        points : 10
    },
    {
        id: 40,
        name: "Om",
        dob: "20/01/1984",
        phone: "9876543210",
        email: "om@o.com",
        address: "Erode",
        points : 4
    },
    {
        id: 41,
        name: "Raashi",
        dob: "29/07/1990",
        phone: "9876543210",
        email: "raashi@r.com",
        address: "Thanjavur",
        points : 2
    },
    {
        id: 42,
        name: "Chennappa",
        dob: "31/12/1977",
        phone: "9876543210",
        email: "chennappa@c.com",
        address: "Tiruppur",
        points : 24
    },
    {
        id: 43,
        name: "Aanya",
        dob: "15/04/2003",
        phone: "9876543210",
        email: "aanya@a.com",
        address: "Chengalpattu",
        points : 454
    },
    {
        id: 44,
        name: "Sakthi",
        dob: "05/10/1982",
        phone: "9876543210",
        email: "sakthi@s.com",
        address: "Salem",
        points : 67
    },
    {
        id: 45,
        name: "Kavitha",
        dob: "11/03/1989",
        phone: "9876543210",
        email: "kavitha@k.com",
        address: "Tiruvannamalai",
        points : 45
    },
    {
        id: 46,
        name: "Gokul",
        dob: "27/09/1976",
        phone: "9876543210",
        email: "gokul@g.com",
        address: "Madurai",
        points : 12
    },
    {
        id: 47,
        name: "Tamil Selvi",
        dob: "13/02/1983",
        phone: "9876543210",
        email: "tamilselvi@t.com",
        address: "Coimbatore",
        points : 11
    },
    {
        id: 48,
        name: "Ilamaran",
        dob: "22/08/1980",
        phone: "9876543210",
        email: "ilamaran@i.com",
        address: "Salem",
        points : 2
    },
    {
        id: 49,
        name: "Bhuvana",
        dob: "29/01/1986",
        phone: "9876543210",
        email: "bhuvana@b.com",
        address: "Chennai",
        points : 4
    },
    {
        id: 50,
        name: "Priya",
        dob: "06/07/1992",
        phone: "9876543210",
        email: "priya@p.com",
        address: "Vellore",
        points : 47
    },
    
];
