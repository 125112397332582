import React from 'react'
import { Area, Bar, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { SalesData } from '../constants/SalesData'
import { Card, CardHeader } from '@mui/material';

const SalesChart = () => {

    // const [opacity, setOpacity] = React.useState({
    //     Sales: 1,
    //     Target: 1,
    //     Purchase: 1
    // });

    // const handleMouseEnter = (o) => {
    //     const { dataKey } = o;

    //     setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    // };

    // const handleMouseLeave = (o) => {
    //     const { dataKey } = o;

    //     setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    // };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip p-3 rounded" style={{ backgroundColor: '#f0daae', opacity: 0.9 }}>
                    <p className="label">{`Month : ${label}`}<br />
                        {`Target : ${payload[3].value}`}<br />
                        {`Sales : ${payload[1].value}`}<br />
                        {`Purshase : ${payload[2].value}`}<br />
                    </p>
                </div>
            );
        }

        return null;
    };
    return (
        <Card>
            <CardHeader title='Sales & Purchases' />
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart width={500} height={400} data={SalesData}>
                    <YAxis />
                    <XAxis dataKey="name" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line type="monotone" dataKey="Target" stroke="#482e1d" />
                    <Bar type="monotone" dataKey={"Sales"} stroke="#895d2b" fill="#895d2b" radius={[8, 8, 0, 0]} />
                    <Area type="monotone" dataKey={"Sales"} stroke="#895d2b" fill="#895d2b" opacity={0.5} />
                    <Bar type="monotone" dataKey={"Purchase"} stroke="#a3966a" fill="#a3966a" radius={[8, 8, 0, 0]} />
                </ComposedChart>
            </ResponsiveContainer>
        </Card>
    )
}

export default SalesChart