import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import SalesReport from './pages/SalesReport';
import ManageTable from './pages/ManageTable';
import Customer from './pages/Customer';
import Settings from './pages/Settings';
import Logout from './pages/Logout';
import Error from './pages/Error';
import Profile from './pages/Profile';
import TableDataCard from './components/TableDataCard';
import { ThemeProviderComponent } from './components/DarkMode/DarkMode/ThemeContext';
import ManageTakeAway from './pages/ManageTakeAway';
import AuthProvider from './Auth/AuthProvider';
import ProtectedRoute from './Auth/ProtectedRoute';
import LoginPage from './pages/Login';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProviderComponent>
    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
              <Route path="/salesreport" element={<ProtectedRoute><SalesReport /></ProtectedRoute>} />
              <Route path="/managetable" element={<ProtectedRoute><ManageTable /></ProtectedRoute>} />
              <Route path="/tabledatacard" element={<ProtectedRoute><TableDataCard /></ProtectedRoute>} />
              <Route path="/customers" element={<ProtectedRoute><Customer /></ProtectedRoute>} />
              <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/takeaway" element={<ProtectedRoute><ManageTakeAway /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </ThemeProviderComponent>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
