import React from 'react'
import {  navimg } from '../images'
import { Breadcrumbs, Typography, Link } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'
// eslint-disable-next-line
import DarkMode from './DarkMode/DarkMode/DarkMode'

const TopHeader = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    return (
        <>
            <div className="col-md-12 d-flex justify-content-between align-items-center my-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link className='fw-bold' component={RouterLink} to="/" underline="hover" color="inherit">
                        Home
                    </Link>
                    {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                        return last ? (
                            <Typography color="text.primary" key={to}>
                                {value}
                            </Typography>
                        ) : (
                            <Link
                                component={RouterLink}
                                to={to}
                                underline="hover"
                                color="inherit"
                                key={to}
                            >
                                {value}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
                <div className="profile d-flex align-items-center justify-content-end">
                    <Link component={RouterLink} to="/profile">
                        <img src={navimg} alt="Logo" height="50px" width="50px" className="img-fluid rounded" />
                    </Link>
                    {/* <DarkMode /> */}
                </div>
            </div>
        </>
    )
}

export default TopHeader