import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthProvider';
import { Button, TextField, ThemeProvider, CircularProgress } from '@mui/material';
import { lightlogo, loginimg1 } from '../images';
import { lightTheme } from '../constants/theme';
import LoginErrorDialog from '../components/LoginErrorDialog';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username === 'Admin' && password === 'admin@123') {
            setLoading(true);
            setTimeout(() => {
                login();
                setLoading(false);
                navigate('/');
            }, 3000);
        } else {
            setErrorDialogOpen(true);
            setPassword('');
        }
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <div>
                <div className="logincontainer">
                    <div className="row loginpagecont">
                        <div className="col-md-6 leftsection001 d-flex flex-column justify-content-center">
                            <div className="welcome">
                                <img src={lightlogo} alt="Logo" width={150} className='img-fluid' />
                                <h1 className='fw-bold'>Bello, <br />Welcome Back</h1>
                                <p style={{ color: 'gray' }}>Hey, Welcome back to your special place</p>
                            </div>
                            <div className="formelement my-5">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        variant="outlined"
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <TextField
                                        className='mt-3'
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{ marginTop: '20px', padding: '10px 25px', backgroundColor: 'secondary.main', textTransform: 'capitalize', fontWeight: '400' }}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Sign in'}
                                    </Button>
                                    <LoginErrorDialog
                                        open={errorDialogOpen}
                                        onClose={() => setErrorDialogOpen(false)}
                                    />
                                </form>
                                <h6 className='mt-5'> Username : <span className='fw-bold' style={{ color: '#895d2b' }}>Admin</span></h6>
                                <h6 >Password : <span className='fw-bold' style={{ color: '#895d2b' }}>admin@123</span></h6>
                            </div>
                        </div>
                        <div className="col-md-6 rightsection d-flex justify-content-center align-items-center">
                            <img src={loginimg1} alt="login" width={'100%'} className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default Login;
