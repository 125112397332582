import { Button, Card } from '@mui/material';
import React, { useEffect } from 'react'
import { TakeAwayData } from '../constants/TakeAway';
import { Link } from 'react-router-dom';

const ManageTakeAway = () => {
    useEffect(() => {
        document.title = 'ByteBliss Coffee - Take Away';
    }, []);

    return (
        <div>
            <h5>Manage Take Away</h5>
            <div className="row">
                {TakeAwayData.map((takeaway, index) => {
                    return (
                        <div className="col-md-4 my-2" key={index}>
                            <Card variant="outlined" className="p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="text-uppercase">{takeaway.customername}</h5>
                                        <ol>
                                            {takeaway.products.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ol>
                                        <p>
                                            No of items : <span className="fw-bold text-capitalize">{takeaway.quantity}</span>
                                        </p>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Button
                                            component={Link}
                                            to={`/takeawaydatacard`}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: takeaway.status === 'Active' ? 'customButton.main' : 'secondary.main',
                                                textTransform: 'capitalize',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Pay Now
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default ManageTakeAway