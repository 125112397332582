export const StockAlert = [
    {
        id : 1,
        productname : "Espresso 404 Powder",
        quantity : 10
    },
    {
        id : 2,
        productname : "Cappuccino Powder",
        quantity : 5
    },
    {
        id : 3,
        productname : "Maida (kg)",  
        quantity : 15  
    },
    {
        id : 4,
        productname : "Paper cup (box)",
        quantity : 19
    },
    {
        id : 5,
        productname : "Dark Chocolate (bar)",
        quantity : 15
    }
]