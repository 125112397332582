import React, { useEffect } from 'react'

const Logout = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Logout'
  })
  return (
    <div>Logout</div>
  )
}

export default Logout