import { Button, Card } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StockAlert } from '../constants/StockAlert'
// eslint-disable-next-line
import SalesChart from '../charts/SalesChart'
import { totals } from '../constants/SalesData'
import { Area, ComposedChart, ResponsiveContainer, Tooltip } from 'recharts'
import { SalesData } from '../constants/SalesData'
import { CustomerData, nocustomers } from '../constants/CustomerData'
import PaymentChart from '../charts/PaymentChart'
import CountUp from 'react-countup';


const HomePage = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Home'
  })
  return (
    <div>
      {/* <div className="col-md-12">
        <SalesChart />
      </div> */}
      <div className="col-md-12 my-4">
        <div className="row">
          {/* Sales Section */}

          <div className="col-md-4">
          <h5 className='fw-bold'>Sales</h5>
            <Card variant='outlined' className='p-3'>
              <div className="col-12">
                <h5>Total Sales this year</h5>
                <h1><CountUp end={totals.totalSales} duration={2.5} separator="," decimals={2} decimal="." prefix="₹" /></h1>
                <ResponsiveContainer width="100%" height={100}>
                  <ComposedChart width={500} height={400} data={SalesData}>
                    <Tooltip />
                    <Area type="monotone" dataKey={"Sales"} stroke="#895d2b" fill="#895d2b" opacity={0.5} />
                  </ComposedChart>
                </ResponsiveContainer>
                <Button component={Link} to={`/salesreport`} variant='contained' sx={{ backgroundColor: 'secondary.main', textTransform: 'capitalize',  marginTop: '25px',color:'white' }}>Sales Report</Button>
              </div>
            </Card>
          </div>

          {/* Customer Section */}
          <div className="col-md-4">
          <h5 className='fw-bold'>Clients</h5>
            <Card variant='outlined' className='p-3'>
              <div className="col-12">
                <h5>Total Customers this year</h5>
                <h1>
                <CountUp end={nocustomers.totalCustomer} duration={2.5}></CountUp>
                </h1>
                <ResponsiveContainer width="100%" height={100}>
                  <ComposedChart width={500} height={400} data={CustomerData}>
                    <Tooltip />
                    <Area type="monotone" dataKey={"NumberofCustomers"} stroke="#a3966a" fill="#a3966a" opacity={0.9} />
                  </ComposedChart>
                </ResponsiveContainer>
                <Button component={Link} to={`/customers`} variant='contained' sx={{ backgroundColor: 'secondary.main', textTransform: 'capitalize', marginTop: '25px', color:'white' }}>customers</Button>
              </div>
            </Card>
          </div>

          {/* Stock Alert Section */}

          <div className="col-md-4">
            <h5 className='fw-bold'>Stock Alert</h5>
            <div style={{ height: 285, width: '100%' }}>
              <DataGrid rows={StockAlert} columns={[
                { field: 'productname', headerName: 'Product Name', width: 200 },
                { field: 'quantity', headerName: 'Quantity', width: 100, renderCell: (params) => <p className='fw-bold text-danger'>{params.row.quantity}</p> },
              ]} />
            </div>
          </div>

          {/* Payment Section */}
          <div className="col-md-12 mt-3">
            <PaymentChart />
          </div>
        </div>
      </div>
    </div >
  )
}

export default HomePage