export const TakeAwayData = [
    {
        id: 1,
        customername : "Kavya",
        products : ["Espresso (Large)" , "Brownie"],
        quantity : 2,
    },
    {
        id: 2,
        customername : "Ilamaran",
        products : ["Cappuccino (Small)" , "Brownie" , "7 up (250 ml)"],
        quantity : 3,
    }
]