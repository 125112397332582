import React from 'react';
import { TableData } from '../constants/TableData'
import { DownloadTwoTone, MoreHorizTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import { lightlogo } from '../images';

const TableDataCard = () => {
    const firstTable = TableData[0];
    const subtotal = firstTable.total(firstTable.orders);
    const taxes = firstTable.tax(subtotal);
    const total = firstTable.fullTotal(subtotal, taxes);

    return (
        <>
            {TableData.map((table, index) => (
                <div className="my-3" key={index}>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-6">
                            <p>{table.name}<br />
                                Download Recipt</p>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <DownloadTwoTone />
                            <MoreHorizTwoTone className='mx-2' />
                        </div>
                    </div>
                    <center><img src={lightlogo} alt="waiter" width={120} className='img-fluid rounded' /></center>
                    <div className="ordersummary my-3">
                        <p className='fw-bold my-3'>Order #{table.id}</p>
                            {TableData.map((table, tableIndex) => (
                                <div key={table.id}>
                                    {table.orders.map((order, orderIndex) => (
                                        <div key={orderIndex}>
                                            {Object.values(order).map((dish, dishIndex) => (
                                                <div key={dishIndex} className="row d-flex justify-content-between align-items-center">
                                                    <div className="col-md-8">
                                                        <p><span className='mx-2'><img src={dish.image} alt="dishimg" width="30%" className='img-fluid rounded' />
                                                        </span>{dish.dishname} <span className="border rounded p-2 "> x{dish.quantity}</span></p>
                                                    </div>
                                                    <div className="col-md-4 d-flex justify-content-end text-end">
                                                        <p className='fw-bold'>₹ {dish.price.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        <p className='fw-bold my-3'>Payment Summary</p>
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-6">
                                <p>Subtotal</p>
                            </div>
                            <div className="col-md-6 text-end fw-bold">₹ {subtotal}</div>
                        </div><hr />
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-6">
                                <p>Taxes</p>
                            </div>
                            <div className="col-md-6 text-end fw-bold">₹ {taxes}</div>
                        </div><hr />
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-6">
                                <p>Total</p>
                            </div>
                            <div className="col-md-6 text-end fw-bold">₹ {total}</div>
                        </div><hr />
                        <div className="paybtn d-flex justify-content-center align-items-center my-3">
                            <Button variant="contained" color="secondary" className='text-capitalize' >Payment for (₹ {total})</Button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default TableDataCard