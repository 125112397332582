import { Box, Button, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuItems } from '../constants/MenuItems';
import { collapcelightlogo, collapsedarklogo, darklogo, lightlogo } from '../images';
import { ChevronLeftTwoTone, ChevronRightTwoTone } from '@mui/icons-material';
import { ThemeContext } from './DarkMode/DarkMode/ThemeContext';
import { AuthContext } from '../Auth/AuthProvider';  
import LogoutConfirmationDialog from './LogoutConfirmationDialog'; 

const SideNav = () => {
    const location = useLocation();
    const [expanded, setExpanded] = useState(true);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const nonLogoutItems = MenuItems.filter(item => item.name !== 'Logout');
    const logoutItem = MenuItems.find(item => item.name === 'Logout');
    const { theme } = useContext(ThemeContext);
    const { logout } = useContext(AuthContext); 
    const navigate = useNavigate();  

    const toggleSidebar = () => {
        setExpanded(!expanded);
    };

    const logo = expanded
        ? theme === 'dark' ? darklogo : lightlogo
        : theme === 'dark' ? collapsedarklogo : collapcelightlogo;

    const handleLogout = () => {
        setLogoutDialogOpen(true);
    };

    const handleConfirmLogout = () => {
        setLoading(true);
        setTimeout(() => {
            logout();  
            setLoading(false);
            setLogoutDialogOpen(false);
            navigate('/login');  
        }, 3000); 
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: expanded ? '210px' : '80px', transition: '0.3s all' }}>
            <div className="d-flex justify-content-around align-items-center">
                <Link to="/"><img src={logo} alt="Logo" width={expanded ? 120 : 100} /></Link>
                <IconButton onClick={toggleSidebar} sx={{ boxShadow: '' }}>
                    {expanded ? <ChevronLeftTwoTone /> : <ChevronRightTwoTone />}
                </IconButton>
            </div>
            <Stack sx={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
                {nonLogoutItems.map((item, index) => {
                    const isActive = location.pathname === item.link;
                    return (
                        <ListItem component={Link} to={item.link} key={index}>
                            {expanded ? (
                                <Button
                                    color={isActive ? 'secondary' : 'primary'}
                                    variant={isActive ? 'contained' : 'outlined'}
                                    fullWidth
                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingX: '10px', paddingY: '15px', borderRadius: '10px' }}
                                >
                                    <ListItemIcon sx={{ color: isActive ? 'customIcon.main' : 'primary.main', mr: 1, minWidth: 'auto' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} sx={{ textTransform: 'capitalize' }} />
                                </Button>
                            ) : (
                                <Tooltip title={item.tooltiptxt} arrow placement="right">
                                    <Button
                                        color={isActive ? 'secondary' : 'primary'}
                                        variant={isActive ? 'contained' : 'outlined'}
                                        fullWidth
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingX: '10px', paddingY: '15px', borderRadius: '10px' }}
                                    >
                                        <ListItemIcon sx={{ color: isActive ? 'customIcon.main' : 'primary.main', minWidth: 'unset' }}>
                                            {item.icon}
                                        </ListItemIcon>
                                    </Button>
                                </Tooltip>
                            )}
                        </ListItem>
                    );
                })}
            </Stack>
            <Box sx={{ mt: '5vh' }}>
                {logoutItem && (
                    <ListItem onClick={handleLogout}>
                        <Button
                            color={logoutItem.customColor}
                            variant={logoutItem.variant}
                            fullWidth
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: expanded ? 'flex-start' : 'center', paddingX: '10px', paddingY: '15px', borderRadius: '10px' }}
                        >
                            <ListItemIcon sx={{ color: `customIcon.main`, mr: expanded ? 1 : 0, minWidth: expanded ? 'auto' : 'unset' }}>
                                {logoutItem.icon}
                            </ListItemIcon>
                            {expanded && <ListItemText primary={logoutItem.name} sx={{ textTransform: 'capitalize' }} />}
                        </Button>
                    </ListItem>
                )}
            </Box>
            <LogoutConfirmationDialog
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                onConfirm={handleConfirmLogout}
                loading={loading}
            />
        </Box>
    );
};

export default SideNav;
