import React, { useEffect } from 'react'
import { SalesData } from '../constants/SalesData'
import { DataGrid } from '@mui/x-data-grid'
import SalesChart from '../charts/SalesChart'

const SalesReport = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee- Sales Report List'
  })
  return (
    <div>
      <SalesChart />
      <div className="row">
        <div className="col-md-6">
          <div className='my-4' style={{ height: 700, width: '100%' }}>
            <h5>Sales Report</h5>
            <DataGrid rows={SalesData} columns={[
              { field: 'name', headerName: 'Month', width: 130 },
              { field: 'Sales', headerName: 'Sales (in ₹)', width: 130 },
              { field: 'Target', headerName: 'Target (in ₹)', width: 130 },
            ]} />
          </div>
        </div>
        <div className="col-md-6">
          <div className='my-4' style={{ height: 700, width: '100%' }}>
            <h5>Purchase Report</h5>
            <DataGrid rows={SalesData} columns={[
              { field: 'name', headerName: 'Month', width: 130 },
              { field: 'Purchase', headerName: 'Purchase (in ₹)', width: 130 },
              { field: 'Target', headerName: 'Target (in ₹)', width: 130 },
            ]} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesReport