import {  AssessmentTwoTone, AssignmentIndTwoTone, DashboardTwoTone, Groups2TwoTone, LogoutTwoTone, SettingsTwoTone, ShoppingBagTwoTone, TableRestaurantTwoTone } from '@mui/icons-material';

export const MenuItems = [
    {
        name: "Dashboard",
        link: "/",
        icon: <DashboardTwoTone />,
        tooltiptxt : "Dashboard"
    },
    {
        name: "Sales Report",
        link: "/salesreport",
        icon: <AssessmentTwoTone />,
        tooltiptxt : "Sales Report"
    },
    {
        name: "Manage Table",
        link: "/managetable",
        icon: <TableRestaurantTwoTone />,
        tooltiptxt : "Manage Table"
    },
    {
        name: "Take Away",
        link: "/takeaway",
        icon: <ShoppingBagTwoTone />,
        tooltiptxt : "Manage Take Away"
    },
    {
        name: "Customers",
        link: "/customers",
        icon: <Groups2TwoTone />,
        tooltiptxt : "Customers"
    },
    {
        name: "Settings",
        link: "/settings",
        icon: <SettingsTwoTone />,
        tooltiptxt : "Settings"
    },
    {
        name : "My Profile",
        link : "/profile",
        icon : <AssignmentIndTwoTone/>,
        tooltiptxt : "My Profile"
    },
    {
        name: "Logout",
        link: "/logout",
        icon: <LogoutTwoTone />,
        customColor : "error",
        variant: 'contained',
        tooltiptxt : "Logout"
    },
    
];
