export const SalesData = [
    {
        id : 1,
        name : "Jan",
        Sales : 6900,
        Purchase : 7800,
        Target : 10000
    },
    {
        id : 2,
        name : "Feb",
        Sales : 9300,
        Purchase : 8000,
        Target : 9200
    },
    {
        id : 3,
        name : "Mar",
        Sales : 11000,
        Purchase : 9800,
        Target : 10100
    },
    {
        id : 4,
        name : "Apr",
        Sales : 13000,
        Purchase : 10000,
        Target : 13000
    },
    {
        id : 5,
        name : "May",
        Sales : 8090,
        Purchase : 10000,
        Target : 14000
    },
    {
        id : 6,
        name : "Jun",
        Sales : 9800,
        Purchase : 10100,
        Target : 14000
    },
    {
        id : 7,
        name : "Jul",
        Sales : 13900,
        Purchase : 14000,
        Target : 14000
    },
    {
        id : 8,
        name : "Aug",
        Sales : 18000,
        Purchase : 15000,
        Target : 17000
    },
    {
        id : 9,
        name : "Sept",
        Sales : 20000,
        Purchase : 15000,
        Target : 14000
    },
    {
        id : 10,
        name : "Oct",
        Sales : 17000,
        Purchase : 15000,
        Target : 20000
    },
    {
        id : 11,
        name : "Nov",
        Sales : 18000,
        Purchase : 15000,
        Target : 20000
    },
    {
        id : 12,
        name : "Dec",
        Sales : 18000,
        Purchase : 15000,
        Target : 20000
    },
];
export const totals = SalesData.reduce((acc, current) => {
    acc.totalSales += current.Sales;
    acc.totalPurchases += current.Purchase;
    acc.totalTargets += current.Target;
    return acc;
}, { totalSales: 0, totalPurchases: 0, totalTargets: 0 });
