import React, { useContext } from 'react';
import SideNav from './SideNav';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from '../constants/theme';
import { ThemeContext } from '../components/DarkMode/DarkMode/ThemeContext';
import TopHeader from './TopHeader';

const Layout = () => {
  const { theme } = useContext(ThemeContext);

  const currentTheme = theme === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2" id="sidenav">
            <SideNav />
          </div>
          <div className="col-md-10" id="contentoutlet">
            <TopHeader />
            <Outlet />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Layout;
