export const CustomerData = [
    {
        id: 1,
        monthname: "Jan",
        NumberofCustomers: 54,
        TargetCustomer : 60
    },
    {
        id: 2,
        monthname: "Feb",
        NumberofCustomers: 55,
        TargetCustomer : 65
    },
    {
        id: 3,
        monthname: "Mar",
        NumberofCustomers: 80,
        TargetCustomer : 65
    },
    {
        id: 4,
        monthname: "Apr",
        NumberofCustomers: 42,
        TargetCustomer : 85
    },
    {
        id: 5,
        monthname: "May",
        NumberofCustomers: 67,
        TargetCustomer : 85
    },
    {
        id: 6,
        monthname: "Jun",
        NumberofCustomers: 37,
        TargetCustomer : 90
    },
    {
        id: 7,
        monthname: "Jul",
        NumberofCustomers: 21,
        TargetCustomer : 90
    },
    {
        id: 8,
        monthname: "Aug",
        NumberofCustomers: 81,
        TargetCustomer : 100
    },
    {
        id: 9,
        monthname: "Sept",
        NumberofCustomers: 102,
        TargetCustomer : 100
    },
    {
        id: 10,
        monthname: "Oct",
        NumberofCustomers: 120,
        TargetCustomer : 100
    },
    {
        id: 11,
        monthname: "Nov",
        NumberofCustomers: 120,
        TargetCustomer : 150
    },
    {
        id: 12,
        monthname: "Dec",
        NumberofCustomers: 82,
        TargetCustomer : 120
    }
];

export const nocustomers = CustomerData.reduce((acc, current) => {
    acc.totalCustomer += current.NumberofCustomers;
    return acc;
}, { totalCustomer: 0 });

