import React, { useEffect } from 'react'
import { navimg } from '../images'

const Profile = () => {
    useEffect(() => {
        document.title = 'ByteBliss Coffee- My Profile'
    })
    return (
        <div>
            <div className="row">
                <h5>My Profile</h5>
                <div className="col-md-6">
                    <img src={navimg} alt="Logo" width={400} className='img-fluid rounded' />
                </div>
                <div className="col-md-6">
                </div>
            </div>
        </div>
    )
}

export default Profile