import React, { useEffect } from 'react';
import { Tables } from '../constants/Tables';
import { Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { CakeTwoTone, FamilyRestroomTwoTone, FavoriteTwoTone, PersonTwoTone } from '@mui/icons-material';

const ManageTable = () => {
  useEffect(() => {
    document.title = 'ByteBliss Coffee - Manage Tables';
  }, []);

  const getIcon = (type) => {
    switch (type) {
      case 'Family':
        return <FamilyRestroomTwoTone fontSize='large' sx={{ color: 'secondary.main' }} />;
      case 'Couple':
        return <FavoriteTwoTone fontSize='large' sx={{ color: 'secondary.main' }} />;
      case 'Single':
        return <PersonTwoTone fontSize='large' sx={{ color: 'secondary.main' }} />;
      case 'Birthday':
        return <CakeTwoTone fontSize='large' sx={{ color: 'secondary.main' }} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <h5>Manage Tables</h5>
      <p>Check in or pay for tables</p>
      <div className="row">
        {Tables.map((table, index) => {
          const columnClass = (table.type === 'Birthday' || table.type === 'Family') ? 'col-md-4' : 'col-md-3';
          return (
            <div className={`${columnClass} my-2`} key={index}>
              <Paper
                variant="outlined"
                className="p-3"
                sx={{
                  backgroundColor: table.status === 'Active' ? 'customIcon.main' : 'white',
                  borderBottom: `4px solid ${table.status === 'Active' ? '#333333' : '#65B741'}`,
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <h1 className="text-uppercase">{table.name}</h1>
                    <p>
                      Status : <span className="fw-bold text-capitalize">{table.status}</span><br />
                      Type : <span className="fw-bold text-capitalize">{table.type}</span>
                    </p>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      component={Link}
                      to={`/tabledatacard`}
                      variant="contained"
                      sx={{
                        backgroundColor: table.status === 'Active' ? 'customButton.main' : 'secondary.main',
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                      }}
                    >
                      {table.status === 'Active' ? 'Pay Now' : 'Check In'}
                    </Button>
                  </div>
                </div>
                {getIcon(table.type)}
              </Paper>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageTable;
