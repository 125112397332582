export const TableData = [
    {
        id: 5312,
        name: "Table 1",
        status: "Active",
        orders: [
            {
                dish1: {
                    dishname: "Espresso 404",
                    quantity: 1,
                    price: 125,
                    image: "https://images.pexels.com/photos/2299028/pexels-photo-2299028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
            },
            {
                dish2: {
                    dishname: "Paneer Sandwich",
                    quantity: 2,
                    price: 99,
                    image: "https://images.pexels.com/photos/1351238/pexels-photo-1351238.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
            },
            {
                dish3: {
                    dishname: "Brownie",
                    quantity: 1,
                    price: 125,
                    image: "https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
            }
        ],
        total: (orders) => orders.reduce((sum, order) => {
            const dishPrice = order[Object.keys(order)[0]].price;
            return sum + (dishPrice * order[Object.keys(order)[0]].quantity);
        }, 0).toFixed(2),
        
        tax: (totalPrice) => (totalPrice * 0.08).toFixed(2),
        fullTotal: (totalPrice, tax) => (parseFloat(totalPrice) + parseFloat(tax)).toFixed(2) // Format full total to 2 decimal places
    },
];
