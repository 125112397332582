import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const LoginErrorDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: 5 }}>
            <DialogTitle sx={{ padding: 5 }}>Invalid Credentials</DialogTitle>
            <DialogContent sx={{ padding: 5 }}>
                <DialogContentText>
                    The username or password you entered is incorrect. <br />Please try again.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 5,paddingRight: 5 }}>
                <Button variant='contained' onClick={onClose} color="secondary" className='text-capitalize px-4 py-2'>
                    Got it
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoginErrorDialog;
