export const Tables = [
    {
        id: 1,
        name: "Ts1",
        status: "Active",
        type : "Couple"
    },
    {
        id: 2,
        name: "Ts2",
        status: "InActive",
        type : "Single"
    },
    {
        id: 3,
        name: "Ts3",
        status: "Active",
        type : "Single"
    },
    {
        id: 4,
        name: "Ts4",
        status: "InActive",
        type : "Single"
    },
    {
        id: 5,
        name: "TL1",
        status: "InActive",
        type : "Family"
    },
    {
        id: 6,
        name: "TL2",
        status: "Active",
        type : "Birthday"
    },
    
]