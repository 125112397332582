import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { Area, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import {PaymentData} from "../constants/PaymentData"

const PaymentChart = () => {
  return (
    <Card variant='outlined' className='p-3'>
                <CardHeader title={'Payments'} />
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart width={500} height={400} data={PaymentData}>
                        <YAxis />
                        <XAxis dataKey="month" />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Target" stroke="#482e1d" />
                        <Area type="monotone" dataKey={"pay_amount"} stroke="#895d2b" fill="#895d2b" opacity={0.9} />
                    </ComposedChart>
                </ResponsiveContainer>
            </Card>
  )
}

export default PaymentChart