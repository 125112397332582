import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';

const LogoutConfirmationDialog = ({ open, onClose, onConfirm, loading }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: 5 }}>
            <DialogTitle sx={{ paddingTop: 5, paddingLeft: 5 }}>Logout</DialogTitle>
            <DialogContent sx={{ padding: 5 }}>
                <DialogContentText>
                    Are you sure you want to logout?
                </DialogContentText>
                {loading && <CircularProgress sx={{ padding: 1 }} />}
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 5,paddingRight: 5 }}>
                {!loading && (
                    <>
                        <Button variant='outlined' onClick={onClose} className='text-capitalize px-4 py-2'>Cancel</Button>
                        <Button variant='contained' onClick={onConfirm} color="error" className='text-capitalize px-4 py-2'>Logout</Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default LogoutConfirmationDialog;
