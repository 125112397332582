import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#482e1d',
        },
        secondary: {
            main: '#895d2b',
        },
        customButton: {
            main: '#a3966a', 
        },
        customIcon: {
            main: '#f0daae', 
        }
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#3e3128', 
            paper: '#3c3028', 
        },
        text: {
            primary: '#ffffff', 
            secondary: '#b0b0b0', 
        },
        primary: {
            main: '#a3966a',
        },
        secondary: {
            main: '#978479', 
        },
        customButton: {
            main: '#ab9b92', 
        },
        customIcon: {
            main: '#f0daae', 
        }
    },
});


export { lightTheme, darkTheme };
