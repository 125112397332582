export const PaymentData = [
    {
        month : "Jan",
        pay_amount : 23000,
        Target : 25000
    },
    {
        month : "Feb",
        pay_amount : 9000,
        Target : 18000
    },
    {
        month : "Mar",
        pay_amount : 18500,
        Target : 24500
    },
    {
        month : "Apr",
        pay_amount : 30000,
        Target : 25000
    },
    {
        month : "May",
        pay_amount : 13000,
        Target : 15000
    },
    {
        month : "Jun",
        pay_amount : 8500,
        Target : 12000
    },
    {
        month : "Jul",
        pay_amount : 7200,
        Target : 12500
    },
    {
        month : "Aug",
        pay_amount :6200,
        Target : 12000
    },
    {
        month : "Sep",
        pay_amount : 10000,
        Target : 13000
    },
    {
        month : "Oct",
        pay_amount : 10000,
        Target : 14200
    },
    {
        month : "Nov",
        pay_amount : 8200,
        Target : 14200
    },
    {
        month : "Dec",
        pay_amount : 19390,
        Target : 20000
    },
];


export const totalpays = PaymentData.reduce((acc, current) => {
    acc.tpay += current.pay_amount;
    return acc;
}, { tpay: 0 });